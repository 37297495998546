import 'trix';

// we override this method to disable the default toolbar
// although from some reason on page refresh it still loads
// the default toolbar
Trix.config.toolbar.getDefaultHTML = () => { return '' };

window.addEventListener('trix-before-initialize', (evt) => {
  let blockAttributes = {};

  // this adds custom headings
  ['h2', 'h3', 'h4', 'h5'].forEach((heading) => {
    blockAttributes[heading.replace('h', 'heading')] = {
      tagName: heading,
      terminal: true,
      breakOnReturn: true,
      group: false
    }
  })
  Object.assign(Trix.config.blockAttributes, blockAttributes)

  //this adds custom stylings
  Object.assign(Trix.config.textAttributes, {
    underline: {
      style: { "textDecoration": "underline" },
      inheritable: true,
      parser: function (element) {
        var style = window.getComputedStyle(element);
        return style.textDecoration === "underline";
      }
    }
  });
})
